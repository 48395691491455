import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Nav from "./../NavBar/Nav";
import { useForm } from "react-hook-form";
import Backdrop from "@mui/material/Backdrop";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import axios from "axios";
import base_url from "../../api/bootapi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
toast.configure();

export default function Resource() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [country1, setcountry] = useState();
  const [countryStatus, setCountryStatus] = useState(false);
  const [region, setregion] = useState();
  const [regionStatus, setRegionStatus] = useState(false);

  const selectCountry = (val) => {
    console.log(val);
    setcountry(val);
  };

  const selectRegion = (val) => {
    console.log(val);
    setregion(val);
  };

  const onSubmit = (data2) => {
    if (!country1) {
      setCountryStatus(true);
      return;
    }

    if (!region) {
      setRegionStatus(true);
      return;
    }
    let first_name = data2.first_name;
    let middle_name = data2.middle_name;
    let last_name = data2.last_name;
    let gender = data2.gender;
    let nationality = country1;
    let place_of_birth = data2.place_of_birth;
    let state = region;
    let passport_number = data2.passport_number;
    let passport_expiry_date = data2.passport_expiry_date;
    let date_of_birth = data2.date_of_birth;
    let address = data2.address;
    let email_id = data2.email_id;
    let phone = data2.phone;
    let status = 0;

    let data = {
      first_name,
      middle_name,
      last_name,
      gender,
      nationality,
      place_of_birth,
      state,
      passport_number,
      passport_expiry_date,
      date_of_birth,
      address,
      phone,
      email_id,
      status,
    };
    console.log(data);
    // setbackdropopen(true);
    postUser(data);
  };

  const postUser = (data) => {
    axios.post(`${base_url.api1}/passport/passport_create_user`, data).then(
      (response) => {
        // toast.success("Success");
        // Swal.fire('Thank You For Contacting Us ')
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Thank You For Contacting Us",
          showConfirmButton: false,
          timer: 1500,
        });
        document.getElementById("myForm").reset();
      },
      (error) => {
        // toast.error("User Not Created!");
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Something went wrong!",
          showConfirmButton: false,
        });
      }
    );
  };

  return (
    <React.Fragment>
      <Nav />

      <div className="title-banner">
        <div className="container">
          <div className="row">
            <h1>Passport Resources</h1>
          </div>
        </div>
      </div>

      <div className="container pt-5 pb-5 mt-5 mb-3">
        <div className="row">
          <div className="col-md-4">
            <div className="terms-content-main">
              <h2>Fill Out The Documents For:-</h2>
              <ol>
                <li>
                  1.Passport Application
                  <br />
                  <a
                    target="_blank"
                    href="https://pptform.state.gov/FraudAbuseNotice.aspx?Submit2=Complete+Online+%26+Print&AspxAutoDetectCookieSupport=1"
                  >
                    Click Here
                  </a>
                </li>
                <li>
                  2.Passport Renewal
                  <br />
                  <a
                    target="_blank"
                    href="https://pptform.state.gov/?Submit2=Complete+Online+%26+Print"
                  >
                    Click Here
                  </a>
                </li>
                <li>
                  3.Passport Application Status
                  <br />
                  <a target="_blank" href="https://passportstatus.state.gov/">
                    Click Here
                  </a>
                </li>
                <li>
                  4. For Those Traveling To Israel
                  <br />
                  <a target="_blank" href="https://israel-entry.piba.gov.il/">
                    Click Here
                  </a>
                </li>
              </ol>
            </div>
          </div>

          <div className="col-md-7">
            <div className="terms-content-main">
              <h2>Planing ahead is what we do!</h2>
              <p>
                During the covid era, people who wanted to travel for any given
                reason, business, pleasure or anything else, found themselves in
                a common problem, they were not passport ready, and the passport
                offices were closed and it was very hard to get a new passport
                or a renewal.
              </p>
              <p>
                we at PLAN AHEAD VIP realized the problem and we are acting on
                it, we have set up a system, that by entering you passport
                information,  we will send you a reminder a year before your
                passport expires, and remind you again 6 months before the
                expiration so you should have ample time to renew you passport
                in time for travel.
              </p>
              <p>Let us do the planning for you!</p>
            </div>
          </div>
        </div>
        <div className="booking-form">
          <form id="myForm" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <span className="form-label">
                    First Name <span style={{ color: "red" }}>*</span>{" "}
                  </span>
                  <input
                    type="text"
                    className="form-control underline-input"
                    {...register("first_name", { required: true })}
                  />
                  {errors.first_name && (
                    <p className="errormessage">Name is Required</p>
                  )}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <span className="form-label">Middle Name</span>
                  <input
                    type="text"
                    className="form-control underline-input"
                    {...register("middle_name", { required: false })}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <span className="form-label">
                Last Name <span style={{ color: "red" }}>*</span>{" "}
              </span>
              <input
                type="text"
                className="form-control underline-input"
                {...register("last_name", { required: true })}
              />
              {errors.last_name && (
                <p className="errormessage">Last Name is Required</p>
              )}
            </div>
            <div className="form-group">
              <span className="form-label">
                Gender <span style={{ color: "red" }}>*</span>
              </span>
              <div>
                <label>
                  <input
                    type="radio"
                    value="male"
                    {...register("gender", { required: true })}
                  />{" "}
                  Male
                </label>
                <label style={{ marginLeft: "10px" }}>
                  <input
                    type="radio"
                    value="female"
                    {...register("gender", { required: true })}
                  />{" "}
                  Female
                </label>
                <label style={{ marginLeft: "10px" }}>
                  <input
                    type="radio"
                    value="other"
                    {...register("gender", { required: true })}
                  />{" "}
                  Other
                </label>
              </div>
            </div>
            <div className="form-group">
              <span className="form-label">
                Nationality<span style={{ color: "red" }}>*</span>
              </span>
              <CountryDropdown
                className="countrydropdown2 form-control underline-input"
                defaultOptionLabel="- Select Country -"
                value={country1}
                onChange={(val) => {
                  setCountryStatus(false);
                  selectCountry(val);
                }}
                // required
              />
              {countryStatus ? (
                <p className="errormessage">Country is Required</p>
              ) : null}
            </div>
            <div className="form-group">
              <span className="form-label">
                State<span style={{ color: "red" }}>*</span>
              </span>
              <RegionDropdown
                className="countrydropdown2 form-control underline-input"
                defaultOptionLabel="- Select State -"
                country={country1}
                value={region}
                onChange={(val) => {
                  setRegionStatus(false);
                  selectRegion(val);
                }}
                // required
              />
              {regionStatus ? (
                <p className="errormessage">State is Required</p>
              ) : null}
            </div>
            <div className="form-group">
              <span className="form-label">
                Place Of Birth <span style={{ color: "red" }}>*</span>{" "}
              </span>
              <input
                type="text"
                className="form-control underline-input"
                {...register("place_of_birth", { required: true })}
              />
              {errors.place_of_birth && (
                <p className="errormessage">Place of Birth is Required</p>
              )}
            </div>
            <div className="form-group">
              <span className="form-label">
                Address <span style={{ color: "red" }}>*</span>{" "}
              </span>
              <input
                type="text"
                className="form-control underline-input"
                {...register("address", { required: true })}
              />
              {errors.address && (
                <p className="errormessage">Address is Required</p>
              )}
            </div>
            <div className="form-group">
              <span className="form-label">
                Passport Number<span style={{ color: "red" }}>*</span>
              </span>
              <input
                type="text"
                className="form-control underline-input"
                {...register("passport_number", { required: true })}
              />
              {errors.passport_number && (
                <p className="errormessage">Passport Number is Required</p>
              )}
            </div>
            <div className="form-group">
              <span className="form-label">
                Passport Expiry Date<span style={{ color: "red" }}>*</span>
              </span>
              <input
                type="date"
                className="form-control underline-input"
                {...register("passport_expiry_date", { required: true })}
              />
              {errors.passport_expiry_date && (
                <p className="errormessage">Passport Expiry Date is Required</p>
              )}
            </div>
            <div className="form-group">
              <span className="form-label">
                Date Of Birth<span style={{ color: "red" }}>*</span>
              </span>
              <input
                type="date"
                className="form-control underline-input"
                {...register("date_of_birth", { required: true })}
              />
              {errors.date_of_birth && (
                <p className="errormessage">Date Of Birth is Required</p>
              )}
            </div>
            <div className="row" />
            <div className="form-group">
              <span className="form-label">
                Email<span style={{ color: "red" }}>*</span>
              </span>
              <input
                type="email"
                className="form-control underline-input"
                {...register("email_id", { required: true })}
              />
              {errors.email_id && (
                <p className="errormessage">Email is Required</p>
              )}
            </div>
            <div className="row" />
            <div className="form-group">
              <span className="form-label">
                Phone<span style={{ color: "red" }}>*</span>
              </span>
              <input
                type="number"
                className="form-control underline-input"
                {...register("phone", { required: true })}
              />
              {errors.phone && (
                <p className="errormessage">Phone is Required</p>
              )}
            </div>
            <div className="form-btn">
              <button className="submit-btn">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}
